<template>
  <div>
    <validation-observer ref="createProject">
      <div>
        <h2 class="font-weight-bolder mb-2 hGoBack">
          <span @click="$router.go(-1)">
            <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
              <feather-icon icon="ArrowLeftCircleIcon" size="35" />
            </b-button>
          </span>
          Crear obra
        </h2>
        <b-card>
          <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
            <b-row>
              <b-col sm="6">
                <!-- name -->
                <validation-provider #default="{ errors }" :name="'Nombre'" rules="required">
                  <b-form-group :label="'Nombre*'" label-for="project-name">
                    <b-form-input v-model="name" name="name" :placeholder="'Nombre*'" />
                  </b-form-group>
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- ref -->
                <validation-provider #default="{ errors }" :name="'ref'" rules="required">
                  <b-form-group :label="'Referencia*'" label-for="ref">
                    <b-form-input v-model="ref" name="ref" :placeholder="'Referencia*'" />
                  </b-form-group>
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- client id -->
                <validation-provider #default="{ errors }" :name="'Cliente'" rules="required">
                  <b-form-group :label="'Cliente*'" label-for="project-client">
                    <v-select label="name" :filterable="true" :searchable="true" :options="selectClients"
                      :placeholder="'Cliente*'" v-model="client" />
                  </b-form-group>
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- description -->
                <b-form-group :label="'Descripción'" label-for="project-description">
                  <quill-editor v-model="description" />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <!-- address -->
                <validation-provider #default="{ errors }" :name="'Dirección'" rules="required">
                  <b-form-group :label="'Dirección*'" label-for="project-address">
                    <b-form-input v-model="address" name="address" :placeholder="'Dirección*'" />
                  </b-form-group>
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- responsable id -->
                <validation-provider #default="{ errors }" :name="'Responsable'" rules="required">
                  <b-form-group :label="'Responsable de mantenimiento'" label-for="project-user">
                    <v-select label="name" :filterable="true" :searchable="true" :options="selectUsers"
                      :placeholder="'Responsable de mantenimiento'" v-model="user" />
                  </b-form-group>
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="12 text-right">
                <b-button type="submit" variant="primary" class="mt-2 mr-1">
                  Guardar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BCollapse,
  VBToggle,
  BLink,
  BInputGroup,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { config } from "@/shared/app.config";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    BFormCheckbox,
    BCollapse,
    BLink,
    config,
    BInputGroup,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      clients: [],
      users: [],
      name: "",
      ref: "",
      description: "",
      departments: [],
      categories: [],
      status: "",
      client: "",
      user: '',
      typeTasks: "",
      required,
      email,
      address: '',
      dataFirstStep: "",
      subcontrata: false,
      importe_facturado: "",
      importe_pendiente: "",
      items: [
        {
          id: 1,
          name: "",
          phone: "",
        },
      ],
      nextTodoId: 2,
      isDisabled: false,
      person_coordinador_seguridad_salud: "",
      phone_coordinador_seguridad_salud: "",
    };
  },
  mounted() {
    this.initTrHeight();
  },
  computed: {
    ...mapGetters({
      selectClients: "clients/getSelectClients",
      selectUsers: "users/getSelectUsers",
      selectCategories: "categories/getItemsCreateProject",
      clientParam: "clients/getClient",
      role: "auth/getRole",
    }),
  },
  methods: {
    ...mapActions({
      create: "projects/create",
      getListCategoriesCreateProject: "categories/getListCategoriesCreateProject",
      searchClients: "clients/selectClients",
      searchUsers: "users/selectUsers",
      getClient: "clients/getClient",
    }),
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      if (this.items.length < 3) {
        this.isDisabled = false;
      }
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
      });
    },
    handleSubmit() {
      this.$refs.createProject.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ project: formData });
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      });
    },
    createFormData() {
      const data = new FormData();
      data.append("name", this.name);
      data.append("ref", this.ref);
      data.append("client_id", this.client.id);
      if (this.description !== '' || this.description !== null) {
        data.append('description', this.description)
      }
      data.append("responsible_id", this.user.id)
      data.append("address", this.address)
      return data;
    },
    setClient() {
      if (this.$route.params.client_param) {
        this.getClient(this.$route.params.client_param).then(() => {
          this.client = this.clientParam;
        });
      }
    },
    hash(s) {
      let hash = 0;
      let i;
      let c;
      const strlen = s.length;
      if (strlen === 0) {
        return hash;
      }
      for (i = 0; i < strlen; i++) {
        c = s.charCodeAt(i);
        hash = (hash << 5) - hash + c;
        hash &= hash; // Convert to 32bit integer
      }
      return hash;
    },

  },
  async created() {
    await this.searchClients()
    await this.setClient();
    await this.searchUsers({
      page: 1, per_page: 999, search: '', roles: ['tec'],
    });

    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.icon-trash {
  padding-top: 7px;
}
</style>
